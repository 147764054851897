<template>
  <div>
    <div class="page-title">
      <h3>{{$t('recordPage.NewRecord')}}</h3>
    </div>
    <loader v-if="loading"/>
    <p class="center" v-else-if="!categories.length">{{$t('recordPage.CategoriesAbsent')}}
      <router-link
        :to="'/categories'"
      >{{$t('recordPage.AddNewCategory')}}</router-link>
    </p>
    <form
      class="form"
      @submit.prevent="createRecord"
      v-else
    >
      <div class="input-field" >
        <select ref="select" v-model="categoryId">
          <option
            v-for="category of categories"
            :key="category.id"
            :value="category.id"
          >
            {{category.name}}
          </option>
        </select>
        <label>{{$t('recordPage.SelectCategory')}}</label>
      </div>
      <p>
        <label>
          <input
            class="with-gap"
            name="type"
            type="radio"
            value="income"
            v-model="type"
          />
          <span>{{$t('recordPage.Income')}}</span>
        </label>
      </p>

      <p>
        <label>
          <input
            class="with-gap"
            name="type"
            type="radio"
            value="outcome"
            v-model="type"
          />
          <span>{{$t('recordPage.Outcome')}}</span>
        </label>
      </p>

      <div class="input-field">
        <input
          id="amount"
          type="number"
          v-model.number="amount"
          :class="{invalid: $v.amount.$dirty && !$v.amount.minValue}"
        >
        <label for="amount">{{$t('recordPage.Amount')}}</label>
        <span
          class="helper-text invalid"
          v-if="$v.amount.$dirty && !$v.amount.minValue"
        >{{$t('recordPage.MinValue')}} {{$v.amount.$params.minValue.min}}
        </span>
      </div>

      <div class="input-field">
        <input
          id="description"
          type="text"
          v-model="description"
          :class="{invalid: $v.description.$dirty && !$v.description.required}"
        >
        <label for="description">{{$t('recordPage.Description')}}</label>
        <span
          class="helper-text invalid"
          v-if="$v.description.$dirty && !$v.description.required"
        >{{$t('recordPage.InputDescription')}}
        </span>
      </div>

      <button class="btn waves-effect waves-light" type="submit">
        {{$t('global.Create')}}
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
export default {
  name: 'Record',
  data () {
    return {
      loading: true,
      categories: [],
      select: null,
      categoryId: null,
      type: 'outcome',
      amount: 1,
      description: ''
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('menu.New record')
    }
  },
  validations: {
    amount: { minValue: minValue(1) },
    description: { required }
  },
  computed: {
    ...mapGetters(['info']),
    createAbility () {
      if (this.type === 'income') {
        return true
      }
      return this.info.bank >= this.amount
    }
  },
  methods: {
    async createRecord () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        if (this.createAbility) {
          const recordData = {
            categoryId: this.categoryId,
            amount: this.amount,
            description: this.description,
            type: this.type,
            date: new Date().toJSON()
          }
          const test = await this.$store.dispatch('createRecord', recordData)
          console.log(test)
          const bank = this.type === 'income'
            ? this.info.bank + this.amount
            : this.info.bank - this.amount
          // bank is object bank: bank const bank is value
          await this.$store.dispatch('updateInfo', { bank })
          this.$message('pluginMessages.RecordCreated')
          this.$v.$reset()
          this.amount = 1
          this.description = ''
        } else {
          this.$message('pluginMessages.NotEnoughMoney', { lackAmount: this.amount - this.info.bank })
        }
      } catch (e) {}
    }
  },
  async mounted () {
    this.categories = await this.$store.dispatch('fetchCategories')
    this.loading = false

    if (this.categories.length) {
      this.categoryId = this.categories[0].id
    }

    setTimeout(() => {
      this.select = M.FormSelect.init(this.$refs.select)
      M.updateTextFields()
    }, 0)
  },
  destroyed () {
    if (this.select && this.select.destroy) {
      this.select.destroy()
    }
  }
}
</script>

<style scoped>

</style>
